import { useAccount } from "wagmi";
import {
    CONTRACT_ABI,
    USDT_TOKEN_ADDRESS,
    BUSD_TOKEN_ADDRESS,
    PRESALE_CONTRACT,
} from "../contact";
import tokenAbi from '../json/token.json'
import { getWeb3 } from "./contractHelper";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


export const useCommonStats = (updater) => {
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        remainingToken: 0,
        perDollarPrice: 0,
        contractBalance: 0,
        UsdtoBNB: 0
    });


    let presaleContract = new web3.eth.Contract(CONTRACT_ABI, PRESALE_CONTRACT);
   

    useEffect(() => {
        const fetch = async () => {
            try {
                
                
                let remainingToken = await presaleContract.methods.remainingToken().call(); //0
                let perDollarPrice = await presaleContract.methods.perDollarPrice().call(); //1
                let contractBalance = await presaleContract.methods.contractBalance().call(); //2
                let UsdtoBNB = await presaleContract.methods.UsdtoBNB().call(); //3
             

                setStats({
                    remainingToken: remainingToken / Math.pow(10, 18),
                    perDollarPrice: perDollarPrice / Math.pow(10, 18),
                    contractBalance: contractBalance / Math.pow(10, 18),
                    UsdtoBNB: UsdtoBNB / Math.pow(10, 18)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}


export const useAccountStats = (updater) => {
    let web3 = getWeb3();
    let { address } = useAccount()


    const [stats, setStats] = useState({
        bnbBalance: 0,
        usdtBalance: 0,
        busdBalance: 0,
        usdtAllownce: 0,
        busdAllownce: 0,
        purchasedToken: 0
    });


    let presaleContract = new web3.eth.Contract(CONTRACT_ABI, PRESALE_CONTRACT);
    let usdtContract = new web3.eth.Contract(tokenAbi, USDT_TOKEN_ADDRESS);
    let busdContract = new web3.eth.Contract(tokenAbi, BUSD_TOKEN_ADDRESS);


    useEffect(() => {
        const fetch = async () => {
            try {

                let bnbBalance = web3.utils.fromWei(await web3.eth.getBalance(address), 'ether')
               
                
                let usdtBalance = await usdtContract.methods.balanceOf(address).call();
                let busdBalance =    await busdContract.methods.balanceOf(address).call();
                let usdtAllownce =    await usdtContract.methods.allowance(address, PRESALE_CONTRACT).call();
                let busdAllownce =    await busdContract.methods.allowance(address, PRESALE_CONTRACT).call();
                let purchasedToken =    await presaleContract.methods.ikeBalance(address).call();


                setStats({
                    bnbBalance,
                    usdtBalance: usdtBalance / Math.pow(10, 18),
                    busdBalance: busdBalance / Math.pow(10, 18),
                    usdtAllownce: usdtAllownce / Math.pow(10, 18),
                    busdAllownce: busdAllownce / Math.pow(10, 18),
                    purchasedToken: purchasedToken / Math.pow(10, 18)
                })
            }
            catch (err) {
                console.log(err);
                toast.error(err.reason)
            }
        }

        if (address) {
            fetch();
        }
        else {
            setStats({
                bnbBalance: 0,
                usdtBalance: 0,
                busdBalance: 0,
                usdtAllownce: 0,
                busdAllownce: 0,
                purchasedToken: 0
            })
        }

        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}
