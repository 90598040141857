import React from "react";
import "./style.css";

const RoadMap = () => {
  return (
    <section class="design-section">
      <div class="timeline">
        <div class="timeline-empty"></div>
        <div class="timeline-middle">
          <div class="timeline-circle"> </div>
        </div>
        <div class="timeline-component timeline-content">
          <h3 className="heading-timeline">April 2023</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Launch of XGPT Token:
          </h1>
          <ul className="para-timeline">
            <li>
              Initiate the launch of the XGPT Token, marking the commencement of
              the ICO project.
            </li>
            <li>
              Private sale of tokens worth $600,000 to management and close
              associates at a discounted price, with 500,000 tokens unlocked.
            </li>
          </ul>
        </div>
        <div class="timeline-component timeline-content">
          <h3 className="heading-timeline">May to June 2023</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Community Building:
          </h1>
          <ul className="para-timeline">
            <li>Focus on community expansion, reaching 25,000 investors.</li>
            <li>
              Providing daily assistance to 15,000 investors, enhancing the
              project's credibility and support.
            </li>
          </ul>
        </div>

        <div class="timeline-middle">
          <div class="timeline-circle"> </div>
        </div>

        <div class="timeline-empty"> </div>
        <div class="timeline-empty"></div>

        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>

        <div class=" timeline-component timeline-content">
          <h3 className="heading-timeline">August to November 2023</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Collaboration and Development:
          </h1>
          <ul className="para-timeline">
            <li>
              Collaborate with 10 different firms globally, including US-based
              and other international entities.
            </li>
            <li>
              Develop a decentralized peer-to-peer (P2P) exchange, currently in
              the testing phase.
            </li>
          </ul>
        </div>
        <div class=" timeline-component timeline-content">
          <h3 className="heading-timeline">November 2023 to January 2024</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Community Expansion:
          </h1>
          <ul className="para-timeline">
            <li>Scale the community to 50,000 investors.</li>
            <li>
              Begin distributing the xGPT magazine to the expanded community.
            </li>
          </ul>
        </div>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <div class="timeline-empty"> </div>
        <div class="timeline-empty"> </div>
        <div class="timeline-middle">
          <div class="timeline-circle"> </div>
        </div>
        <div class="timeline-component timeline-content">
          <h3 className="heading-timeline">January to April 2024</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>ICO Launch:</h1>
          <ul className="para-timeline">
            <li>
              Launch the ICO, marking a pivotal milestone in the project's
              timeline.
            </li>
            <li>
              Launch an AI application tailored for market research, potentially
              revolutionizing data analysis and insights.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;
