import { ethers } from "ethers";
import Web3 from "web3";

export const getContract = (abi, address, library) => {
    try {
        return new ethers.Contract(address, abi, library)
    }
    catch {
        return false;
    }
}


export const formatPrice = (num, decimals = 8) => {
    if (num >= 100) {
        return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimals }).format(num);
    }
    return parseFloat(num).toFixed(4)
    //return parseFloat(num.toFixed(decimals)).toLocaleString();

}

export const getWeb3 = () => {
    return new Web3(process.env.REACT_APP_RPC_URL);
}