import Main from "./components/Main";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import {  bsc, bscTestnet } from "viem/chains";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;


// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  version: "2",
});

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <ToastContainer/>
      <Main />
    </WagmiConfig>
  );
}

export default App;
